(function(doc) {
    'use strict';

    var ACX = {
        started: false,
        widgets: [],
        widgetSource: ''
    };

    ACX.findWidgets = function() {
        var widgets = doc.querySelectorAll('.acx-widget');
        var widget = '';

        for (var i = 0; i < widgets.length; i++) {
            widget = widgets[i];
            this.widgets.push({
                name: widget.src,
                element: widget
            });
        }
    };

    ACX.findWidgetSource = function() {
        var widgetScript = doc.querySelector('.acx-widget-script');

        if (widgetScript && widgetScript.getAttribute('src')) {
            this.widgetSource = widgetScript.getAttribute('src').split('/scripts')[0];
        }
    };

    ACX.findWidget = function(widgetName) {
        var widget = '';

        for (var i = 0; i < this.widgets.length; i++) {
            widget = this.widgets[i];
            if (widget.name === widgetName) {
                return widget.element;
            }
        }
    };

    ACX.sizeWidget = function(widgetName, newSize) {
        var widget = this.findWidget(widgetName);

        if (!widget.getAttribute('height') || (widget.getAttribute('height') && widget.getAttribute('height').length === 0)) {
            widget.style.height = newSize + 'px';
            widget.style.width = widget.width;
            widget.style.overflow = 'hidden';
            widget.scrolling = 'no';
        }
    };

    ACX.start = function() {
        this.started = true;
        this.findWidgets();
        this.findWidgetSource();

        window.addEventListener('message', function(event) {
            if (event.origin !== this.widgetSource) {
                return;
            }

            if (event.data && event.data.height && event.data.name && !isNaN(event.data.height)) {
                this.sizeWidget(event.data.name, parseInt(event.data.height, 10));
            }
        }.bind(this), false);
    };

    if (!window.ACX || !window.ACX.started) {
        window.ACX = ACX;
        if (window.document.readyState === 'complete') {
            window.ACX.start();
        } else {
            window.addEventListener('DOMContentLoaded', function() {
                window.ACX.start();
            });
        }
    }
})(document);
